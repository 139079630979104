import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../home/hero"
import SelectedProjects from "../home/selectedProjects"
import Introduction from "../home/introduction"
import Clients from "../home/clients"
import { Helmet } from "react-helmet"
import Offer from "../home/offer.js"

const IndexPage = ({
  data: { site, contentfulHeader, contentfulFooter, contentfulHomePage },
  uri,
}) => {
  const { logo } = contentfulHeader
  const { siteMetadata } = site

  const {
    pageTitle,
    pageDescription: { pageDescription },
    pageImage,
    heroTagline: { heroTagline },
    heroMotto,
    selectedProjectsTitle,
    selectedProjects,
    introductionImage,
    introductionTitle,
    introductionSubtitle: { introductionSubtitle },
    introductionText,
    clientsImage,
    clientsTitle,
    clientsSubtitle,
    clientsText,
    clients,
    offerTitle,
    title1,
    title2,
    title3,
    title4,
    description1,
    description2,
    description3,
    description4,
  } = contentfulHomePage

  return (
    <Layout
      header={contentfulHeader}
      footer={contentfulFooter}
      pageMeta={{ pageTitle, pageDescription, pageImage, uri }}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: siteMetadata.siteUrl,
            logo: `${siteMetadata.siteUrl}${logo.localFile.publicURL}`,
          })}
        </script>
      </Helmet>
      <Hero
        logo={logo}
        tagline={heroTagline}
        motto={heroMotto}
        selectedProjectsTitle={selectedProjectsTitle}
      />
      <SelectedProjects selectedProjects={selectedProjects} />
      <Introduction
        image={introductionImage}
        title={introductionTitle}
        subtitle={introductionSubtitle}
        text={introductionText}
      />
      <Offer
        offerTitle={offerTitle}
        title1={title1}
        title2={title2}
        title3={title3}
        title4={title4}
        description1={description1}
        description2={description2}
        description3={description3}
        description4={description4}
      />
      <Clients
        image={clientsImage}
        title={clientsTitle}
        subtitle={clientsSubtitle}
        text={clientsText}
        clients={clients}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulHeader {
      ...HeaderInformation
    }
    contentfulFooter {
      ...FooterInformation
    }
    contentfulHomePage {
      pageTitle
      pageDescription {
        pageDescription
      }
      pageImage {
        localFile {
          childImageSharp {
            fixed(width: 1200) {
              width
              height
              src
            }
          }
        }
      }
      #      heroImage {
      #        description
      #        localFile {
      #          childImageSharp {
      #            fluid(
      #              maxWidth: 768
      #              maxHeight: 2304
      #              toFormat: JPG
      #              jpegQuality: 60
      #            ) {
      #              ...GatsbyImageSharpFluid
      #            }
      #          }
      #        }
      #      }
      heroTagline {
        heroTagline
      }
      heroMotto
      selectedProjectsTitle
      introductionImage {
        description
        localFile {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      introductionTitle
      introductionSubtitle {
        introductionSubtitle
      }
      introductionText {
        json
      }

      selectedProjects {
        projectTitle
        projectSubtitle
        projectText {
          projectText
        }
        slug
        projectImage {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      clientsImage {
        description
        localFile {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      clientsTitle
      clientsSubtitle
      clientsText {
        json
      }
      clients {
        link
        logo {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      offerTitle
      title1
      title2
      title3
      title4
      description1 {
        description1
      }
      description2 {
        description2
      }
      description3 {
        description3
      }
      description4 {
        description4
      }
    }
  }
`
