import React from "react"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer.js"

const Container = styled.section`
  background: linear-gradient(198.53deg, #cd2960 -1.37%, #ffd66f 112.65%);
  color: #fff;
  padding: 3rem 0;
  margin-top: 3rem;
`

const Title = styled.h2`
  text-align: center;
  margin: 0;
  font-size: 2.25rem;
`

const Offers = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem;
  }
`

const OfferContainer = styled.div`
  margin-top: 4rem;

  @media screen and (min-width: 768px) {
    width: 50%;
    padding: 1rem;
  }
`

const OfferTitle = styled.h3`
  margin: 0;
  text-transform: uppercase;
`

const OfferDescription = styled.div`
  margin-top: 2rem;
  max-width: 512px;
`

const Offer = ({
  offerTitle,
  title1,
  title2,
  title3,
  title4,
  description1,
  description2,
  description3,
  description4,
}) => {
  console.log(description1)
  return (
    <Container>
      <WidthContainer>
        <Title>{offerTitle}</Title>
        <Offers>
          <OfferContainer>
            <OfferTitle>{title1}</OfferTitle>
            <OfferDescription>{description1.description1}</OfferDescription>
          </OfferContainer>
          <OfferContainer>
            <OfferTitle>{title2}</OfferTitle>
            <OfferDescription>{description2.description2}</OfferDescription>
          </OfferContainer>
          <OfferContainer>
            <OfferTitle>{title3}</OfferTitle>
            <OfferDescription>{description3.description3}</OfferDescription>
          </OfferContainer>
          <OfferContainer>
            <OfferTitle>{title4}</OfferTitle>
            <OfferDescription>{description4.description4}</OfferDescription>
          </OfferContainer>
        </Offers>
      </WidthContainer>
    </Container>
  )
}

export default Offer
