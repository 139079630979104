import React from "react"

import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { IoIosArrowRoundForward } from "react-icons/io"
import { Link } from "gatsby"
// import { TextContainer } from "../components/textContainer"
import { useInView } from "react-intersection-observer"

const Container = styled.section`
  width: 100%;
  height: 100%;
  //height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);
  position: relative;
  //overflow: hidden;
`

const Background = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Overlay = styled.div`
  background: #000;
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const MainContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: 576px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const TopContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-width: 327px;
  margin: 0 auto;
`

const DividerContainer = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
  position: relative;
`

const Divider = styled.div`
  height: 0.375rem;
  background: #fff;
  width: ${props => (props.active ? "100%" : "0")};
  transition: width 250ms ease-in-out;
`

const BottomContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 327px;
  margin: 0 auto;
`

const ProgressBarContainer = styled.div`
  position: sticky;
  //left: 0;
  //right: 0;
  bottom: 1rem;
  height: 0.5rem;
  background: rgba(255, 255, 255, 0.25);
  margin: 0 0 1rem;
`

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.5rem;
  background: #fff;
`

const Title = styled.h2`
  font-size: 1.75rem;
  text-align: center;
  width: 100%;
  margin: 0 auto 0.75rem;
  line-height: 1.4;
`

const Description = styled.p`
  text-align: center;
  width: 100%;
  margin: 0.5rem auto 0;
`

const LinkButton = styled(Link)`
  margin-top: 2rem;
  display: inline-block;
  border: 1px solid #fff;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding: 0.5rem 3.5rem 0.5rem 1.5rem;
  font-weight: 500;
`

const LinkIcon = styled(IoIosArrowRoundForward)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`

const Subtitle = styled.h3`
  width: 100%;
  margin: 1rem auto 0;
  text-align: center;
  font-weight: 500;
`

const SelectedProject = ({ project, progress }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const {
    projectTitle,
    projectSubtitle,
    projectText: { projectText },
    projectImage,
    slug,
  } = project

  return (
    <Container ref={ref}>
      <Background
        alt={projectImage.description}
        fluid={projectImage.localFile.childImageSharp.fluid}
      />
      <Overlay />
      <MainContent>
        <TopContainer>
          <Title>{projectTitle}</Title>
        </TopContainer>
        <DividerContainer>
          <Divider active={inView} />
        </DividerContainer>
        <BottomContainer>
          <Subtitle> {projectSubtitle}</Subtitle>
          <Description>{projectText}</Description>
          <LinkButton to={`/projects/${slug}/`}>
            Learn more
            <LinkIcon size={"2rem"} />
          </LinkButton>
        </BottomContainer>
        <ProgressBarContainer>
          <ProgressBar
            style={{
              width: `${progress * 100}%`,
            }}
          />
        </ProgressBarContainer>
      </MainContent>
    </Container>
  )
}

SelectedProject.defaultProps = {
  project: {},
  progress: 0.0,
}

export default SelectedProject
