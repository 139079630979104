import React from "react"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import { IoIosArrowDown } from "react-icons/io"
import HeaderLogo from "../components/headerLogo"
// import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  min-height: 100vh;
  //min-height: calc(var(--init-vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  position: relative;
`

const Tagline = styled.h2`
  white-space: pre-line;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.4;
  color: ${props => props.theme.textColorEmphasis};

  //@media screen and (max-height: 575px) {
  //  white-space: normal;
  //}

  @media screen and (min-width: 768px) {
    font-size: 3rem;
    line-height: 1.25;
  }
`

const Motto = styled.h1`
  font-size: 1.125rem;
  font-weight: 300;
  width: 327px;
  margin: 1rem 0 0;

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
    width: 450px;
    margin-top: 2rem;
  }
`

const TopSpacer = styled.div`
  height: 3rem;

  //@media screen and (max-height: 575px) {
  //  height: auto;
  //  flex: 1;
  //}

  @media screen and (min-width: 768px) {
    height: auto;
    flex: 2;
  }
`

const ContentContainer = styled.div`
  //@media screen and (max-height: 575px) {
  //  max-width: unset;
  //  //  padding-left: 0;
  //}
  margin-left: 1rem;

  @media (min-width: 576px) {
    margin-left: 2rem;
  }
`

const BottomSpacer = styled.div`
  flex: 2;
  min-height: 3rem;

  @media screen and (min-width: 768px) {
    height: auto;
    flex: 4;
  }
`

const WorkTitleContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  position: sticky;
  bottom: 1rem;
  //left: 0;
  width: 100%;
  //align-self: flex-end;

  //@media screen and (max-height: 575px) {
  //  position: static;
  //}
`

const WorkTitle = styled.span`
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.75rem;
`

const ArrowDownIcon = styled(IoIosArrowDown)`
  vertical-align: text-bottom;
  display: inline-block;
  margin-left: 0.5rem;
`

const BackgroundContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 160%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`

const BackgroundSvg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  transform: translate3d(42%, 10%, 0);
  margin-left: auto;
  //background: aqua;
`

const Hero = ({ logo, tagline, motto, selectedProjectsTitle }) => {
  return (
    <Container>
      <BackgroundContainer>
        <BackgroundSvg version="1.1" viewBox="0 0 100 100">
          <filter
            id="filterBlur"
            x="-100%"
            y="-100%"
            width="300%"
            height="300%"
          >
            <feGaussianBlur stdDeviation="5" />
          </filter>
          <g filter="url(#filterBlur)">
            <path
              d="M50,10 L15.36,70 L84.64,70 L50,10Z"
              style={{
                fill: "url(#linearGradient1)",
                fillRule: "nonzero",
              }}
            />
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="360 50 50"
              to="0 50 50"
              values="360 50 50;160 50 50;330 50 50;180 50 50;0 50 50"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
              calcMode="spline"
              dur="60s"
              repeatCount="indefinite"
            />
          </g>
          <defs>
            <linearGradient id="linearGradient1">
              <stop offset="0" style={{ stopColor: "#FF7971" }}>
                <animate
                  attributeName="stop-color"
                  values="#FF7971; #FFBB72; #FFA7A1; #FF7971"
                  dur="13s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="1" style={{ stopColor: "rgb(197,28,94)" }} />
            </linearGradient>
          </defs>
        </BackgroundSvg>
      </BackgroundContainer>
      <WidthContainer>
        <HeaderLogo logo={logo} hideMd />
      </WidthContainer>
      <TopSpacer />
      <WidthContainer>
        <ContentContainer>
          <Tagline>{tagline}</Tagline>
          <Motto>{motto}</Motto>
        </ContentContainer>
      </WidthContainer>
      <BottomSpacer />
      <WorkTitleContainer>
        <WorkTitle>{selectedProjectsTitle}</WorkTitle>
        <ArrowDownIcon size={"1.125rem"} />
      </WorkTitleContainer>
    </Container>
  )
}

export default Hero
