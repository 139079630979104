import React, { useState } from "react"

import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  margin-top: 4rem;

  @media screen and (min-width: 768px) {
    margin-top: 6rem;
  }
`

const InnerContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 704px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  @media screen and (min-width: 992px) {
    width: 928px;
  }

  @media screen and (min-width: 1200px) {
    width: 1104px;
  }

  @media screen and (min-width: 1400px) {
    width: 1304px;
  }
`

const SubtitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  //@media screen and (min-width: 576px) {
  //  left: 2rem;
  //}

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const SubtitleContainerMd = styled(SubtitleContainer)`
  @media screen and (min-width: 768px) {
    display: block;
    position: static;
    padding: 0;
  }
`

const Subtitle = styled.h2`
  margin: 0;
  font-size: 2.25rem;
  text-transform: uppercase;
  color: ${props => props.theme.textColorEmphasis};
  white-space: pre-line;
  line-height: 1.4;
`

const ImageContainer = styled.div`
  position: relative;
  padding-top: 2rem;

  @media screen and (min-width: 768px) {
    margin-top: 2rem;
    padding-top: 0;
    width: 45%;
    padding-right: 1rem;
  }
`

const MdContentContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 55%;
    padding-left: 1rem;
  }
`

const IntroImage = styled(Img)`
  margin-left: 3rem;

  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  margin-top: 1rem;

  @media screen and (min-width: 768px) {
    margin-top: 3rem;
  }
`

const Title = styled.h3`
  margin: 0;
  font-size: 3rem;
  white-space: nowrap;
  line-height: 1;
  color: white;
  -moz-text-fill-color: #fff;
  -webkit-text-fill-color: #fff;
  -moz-text-stroke-color: ${props => props.theme.colorPrimary};
  -webkit-text-stroke-color: ${props => props.theme.colorPrimary};
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-width: 1px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-right: 4rem;

  @media screen and (min-width: 768px) {
   font-size: 3.5rem;
  }
`
const Description = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  p {
    margin: 0;
  }

  @media screen and (min-width: 576px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const DescriptionMd = styled(Description)`
  @media screen and (min-width: 768px) {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`

const Introduction = ({ image, title, subtitle, text }) => {
  const [displacement, setDisplacement] = useState(0)

  useScrollPosition(({ currPos: { y } }) => {
    setDisplacement((-y / window.document.documentElement.clientHeight) % 1)
  })

  return (
    <Container>
      <InnerContainer>
        <ImageContainer>
          <IntroImage
            alt={image.description}
            fluid={image.localFile.childImageSharp.fluid}
          />
          <SubtitleContainer>
            <TextContainer>
              <Subtitle>{subtitle}</Subtitle>
            </TextContainer>
          </SubtitleContainer>
        </ImageContainer>
        <MdContentContainer>
          <SubtitleContainerMd>
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleContainerMd>
          <DescriptionMd>{documentToReactComponents(text.json)}</DescriptionMd>
        </MdContentContainer>
      </InnerContainer>
      <TitleContainer>
        <Title
          style={{
            transform: `translate3d(-${displacement * 100}%, 0, 0)`,
          }}
        >
          {title}
        </Title>
        <Title
          style={{
            transform: `translate3d(-${displacement * 100}%, 0, 0)`,
          }}
        >
          {title}
        </Title>
      </TitleContainer>
      <Description>
        <TextContainer>{documentToReactComponents(text.json)}</TextContainer>
      </Description>
    </Container>
  )
}

export default Introduction
