import React, { useRef, useState } from "react"

import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { WidthContainer } from "../components/widthContainer"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { InView } from "react-intersection-observer"
import { TextContainer } from "../components/textContainer"

const Container = styled.section`
  margin-top: 5rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) {
    margin-top: 7rem;
  }
`

const InnerContainer = styled.div`
  @media screen and (min-width: 768px) {
    //width: 704px;
    //margin-left: auto;
    //margin-right: auto;
    display: flex;
  }
`

const SubtitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const SubtitleContainerMd = styled(SubtitleContainer)`
  @media screen and (min-width: 768px) {
    display: block;
    position: static;
    padding: 0;
  }
`

const Subtitle = styled.h2`
  margin: 0;
  font-size: 2.25rem;
  text-transform: uppercase;
  color: ${props => props.theme.textColorEmphasis};
  line-height: 1.4;
`

const MdContentContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    margin-left: auto;
    padding-right: 1rem;
    width: 316px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    width: 55%;
    padding-left: 1rem;
  }
`

const Background = styled.div`
  position: absolute;
  top: 4rem;
  bottom: 0;
  left: 2rem;
  width: calc(100% - 3rem);
  background: ${props => props.theme.colorPrimary};
  background: linear-gradient(
    135deg,
    ${props => props.theme.colorPrimary} 10%,
    ${props => props.theme.colorSecondary} 90%
  );
  z-index: -1;
`

const MainImage = styled(Img)`
  margin-left: 3rem;
  position: relative;
`

const Title = styled.h3`
  margin: 1rem 0 0 0;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const TitleMd = styled(Title)`
  @media screen and (min-width: 768px) {
    display: block;
  }
`

const Description = styled.div`
  margin-top: 0.5rem;
  color: ${props => props.theme.textColorLight};

  p {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const DescriptionMd = styled(Description)`
  @media screen and (min-width: 768px) {
    display: block;
  }
`

const ClientImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    margin-top: 3rem;
  }
`

const ClientImage = styled.div`
  transform: ${props => (props.active ? `translateY(0)` : "translateY(100%)")};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 250ms ease-in;
`

const ClientImageLink = styled.a`
  display: block;
  width: 33.333%;
  transform: ${props => (props.active ? `translateY(0)` : "translateY(100%)")};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 250ms ease-in;

  @media screen and (min-width: 768px) {
    width: 25%;
  }
`

const Clients = ({ image, title, subtitle, text, clients }) => {
  const [displacement, setDisplacement] = useState(0)
  const imageContainerRef = useRef(null)

  useScrollPosition(({ currPos: { y } }) => {
    setDisplacement(
      Math.min(
        (Math.max(
          -y +
            window.document.documentElement.clientHeight -
            imageContainerRef.current.offsetTop -
            window.document.documentElement.clientHeight / 4,
          0
        ) /
          window.document.documentElement.clientHeight) *
          1.5,
        1
      )
    )
  })

  return (
    <Container>
      <InnerContainer>
        <MdContentContainer>
          <SubtitleContainerMd>
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleContainerMd>
          <TitleMd>{title}</TitleMd>
          <DescriptionMd>{documentToReactComponents(text.json)}</DescriptionMd>
        </MdContentContainer>
        <ImageContainer ref={imageContainerRef}>
          <MainImage
            alt={image.description}
            fluid={image.localFile.childImageSharp.fluid}
            style={{ left: `${(1 - displacement) * 100}%` }}
          />
          <Background
            style={{
              transform: `translate3d(-${(1 - displacement) * 120}%, 0, 0)`,
            }}
          />
          <SubtitleContainer>
            <TextContainer>
              <Subtitle>{subtitle}</Subtitle>
            </TextContainer>
          </SubtitleContainer>
        </ImageContainer>
      </InnerContainer>
      <WidthContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Description>{documentToReactComponents(text.json)}</Description>
        </TextContainer>
        <InView triggerOnce rootMargin={"-25% 0% -25% 0%"}>
          {({ inView, ref, entry }) => (
            <ClientImages ref={ref}>
              {clients.map(({ link, logo }, index) => {
                if (link) {
                  return (
                    <ClientImageLink
                      key={index}
                      href={link}
                      target="_blank"
                      active={inView}
                      style={{
                        transitionDelay: `${index * 50}ms`,
                      }}
                      rel="noreferrer"
                    >
                      <Img
                        alt={logo.description}
                        fadeIn={false}
                        fluid={logo.localFile.childImageSharp.fluid}
                        style={{ height: "100%" }}
                        objectFit={"contain"}
                      />
                    </ClientImageLink>
                  )
                }
                return (
                  <ClientImage
                    key={index}
                    active={inView}
                    style={{
                      transitionDelay: `${index * 50}ms`,
                    }}
                  >
                    <Img
                      fadeIn={false}
                      fluid={logo.localFile.childImageSharp.fluid}
                      style={{ height: "100%" }}
                      objectFit={"contain"}
                    />
                  </ClientImage>
                )
              })}
            </ClientImages>
          )}
        </InView>
      </WidthContainer>
    </Container>
  )
}

Clients.defaultProps = {
  clients: [],
}

export default Clients
