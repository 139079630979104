import React, { useRef, useState } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import styled from "styled-components"
import SelectedProject from "./selectedProject"

const OuterContainer = styled.div`
  height: ${props => props.count + 1}00vh;
  position: relative;
  width: 100%;
  color: #fff;
`

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`

const ProjectsContainer = styled.div`
  position: absolute;
  top: 0;
  left: -${props => props.displacement}00%;
  width: ${props => props.count}00%;
  height: 100%;
  display: flex;
  transition: left 250ms ease-in-out;
`

const SelectedProjects = ({ selectedProjects }) => {
  const [screenHeightFromTop, setScreenHeightFromTop] = useState(0)
  const containerRef = useRef(null)
  const stickyContainerRef = useRef(null)

  useScrollPosition(({ currPos: { y } }) => {
    setScreenHeightFromTop(
      Math.max(-y / stickyContainerRef.current.clientHeight, 0)
    )
  })

  return (
    <OuterContainer count={selectedProjects.length} ref={containerRef}>
      <StickyContainer ref={stickyContainerRef}>
        <ProjectsContainer
          count={selectedProjects.length}
          displacement={Math.max(
            Math.min(
              Math.floor(screenHeightFromTop - 1),
              selectedProjects.length - 1
            ),
            0
          )}
        >
          {selectedProjects.map((project, index) => {
            return (
              <SelectedProject
                key={index}
                project={project}
                progress={Math.max(
                  Math.min(screenHeightFromTop - 1 - index, 1),
                  0
                )}
              />
            )
          })}
        </ProjectsContainer>
      </StickyContainer>
    </OuterContainer>
  )
}

SelectedProjects.defaultProps = {
  selectedProjects: [],
}

export default SelectedProjects
